(function () {
    Vue.component("cookie-monster", {
        props: ["countryCode", "cookieName", "i18n"],
        data: function () {
            return {
                hasAcceptedCookies: false,
                isModalOpen: false,
                cookieTecnic: true,
                cookieAnalisis: true,
                cookieMarketing: true,
            };
        },
        template: `<div>
    <div v-if="hasToCheckGDPR">
      <div v-if="!isModalOpen" class="cookie-monster__container">  
        <div class="cookie-monster__container__content">
          <div class="cookie-monster__container__content__text">
          <h2 class="cookie-monster__container__content_title">{{i18n.txtCookieMonsterBannerTitle}}</h2>
          <p class="cookie-monster__container__content_description" v-html="i18n.txtCookieMonsterBannerText"></p>
          </div>
          <div class="cookie-monster__container__content__buttons">
            <div class="cookie-monster__container__content__buttons__ctas">
                <button class="btn btn-success cookie-monster__container__content__action" @click="acceptAllCookies">
                  {{i18n.txtCookieMonsterAcceptCookies}}
                </button>
                <button class="btn btn-success cookie-monster__container__content__action" @click="rejectAllCookies">
                  {{i18n.txtCookieMonsterRejectCookies}}
                </button>
            </div>
            <button class="cookie-monster__container__content__link" @click="openModal">
            {{i18n.txtCookieMonsterConfiguration}}
            </button>
          </div>
        </div>
      </div>
      <!-- Cookie Configure Modal -->
      <div v-if="isModalOpen" class="cookie-monster-modal--mask" ref="cookieMonsterModal">
        <div class="cookie-monster-modal__wrapper">
          <div class="cookie-monster-modal__content" ref="cookieMonsterModalContent">
          <!-- Title -->
            <div v-if="isResponsiveMode" ref="header" class="cookie-monster-modal__responsive-header" @click="closeModal">
             <img class="cookie-monster-modal__responsive-header_arrow" src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/arrow_left.svg"/><!-- Only Responsive -->
             <h2>{{i18n.txtCookieMonsterPopupTitle}}</h2> 
            </div>
            <div v-if="!isResponsiveMode" class="cookie-monster-modal__header">
             <div class="cookie-monster-modal__close">
              <h2 class="selector-icon-modal__title">{{i18n.txtCookieMonsterPopupTitle}}</h2>
              <button type="button" class="cookie-monster-modal__close-button" aria-label="Close" @click="closeModal">
                <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross_gray.svg">
              </button>
             </div>
            </div>
            <!-- End Title -->
            <div class="cookie-monster-modal__body">
              <div class="cookie-monster-modal__body__option">
                <label class="wikiloc-checkbox cookie-monster-checkbox">
                  <input type="checkbox" value="true" disabled v-model="cookieTecnic"/>
                  <span class="green"></span>
                  <span class="cookie-monster-modal__body__checkbox-text">
                    {{i18n.txtCookieMonsterPopupCookie1Title}}
                  </span>
                </label>
                <div class="cookie-monster-modal__body__text">
                    {{i18n.txtCookieMonsterPopupCookie1Text}}
                </div>
              </div>
              <div class="cookie-monster-modal__body__option">
                <label class="wikiloc-checkbox cookie-monster-checkbox">
                  <input type="checkbox" value="true" v-model="cookieAnalisis"/>
                  <span class="green"></span>
                  <span class="cookie-monster-modal__body__checkbox-text">
                    {{i18n.txtCookieMonsterPopupCookie2Title}}
                  </span>
                </label>
                <div class="cookie-monster-modal__body__text">
                  {{i18n.txtCookieMonsterPopupCookie2Text}}
                </div>
              </div>
              <div class="cookie-monster-modal__body__option">
                <label class="wikiloc-checkbox cookie-monster-checkbox">
                  <input type="checkbox" value="true" v-model="cookieMarketing"/>
                  <span class="green"></span>
                  <span class="cookie-monster-modal__body__checkbox-text">
                    {{i18n.txtCookieMonsterPopupCookie3Title}}
                  </span>
                </label>              
                <div class="cookie-monster-modal__body__text">
                 {{i18n.txtCookieMonsterPopupCookie3Text}}
                </div>
              </div>
              </div>
            <div class="cookie-monster-modal__footer">
              <div class="cookie-monster-modal__body__accept-cookies">
                <button class="btn btn-success cookie-monster__container__content__action" @click="acceptCookies">{{i18n.txtCookieMonsterAcceptSelectedCookies}}</button>
              </div>
              <div class="cookie-monster-modal__body__allcookies">
                <button class="btn btn-success cookie-monster__container__content__action" @click="acceptAllCookies">{{i18n.txtCookieMonsterAcceptAllCookies}}</button>
              </div>
            </div>
          </div>
        </div>    
      </div>
    </div>
  </div>     
    `,
        watch: {
            isModalOpen: isModalOpen,
        },
        methods: {
            acceptCookies: acceptCookies,
            acceptAllCookies: acceptAllCookies,
            rejectAllCookies: rejectAllCookies,
            openModal: openModal,
            closeModal: closeModal,
            onClickOutside: onClickOutside,
        },
        computed: {
            isResponsiveMode: isResponsiveMode,
            countriesWithCookieLaw: countriesWithCookieLaw,
            hasLawWithCookies: hasLawWithCookies,
            hasToCheckGDPR: hasToCheckGDPR,
        },
        beforeMount: beforeMount,
    });

    function beforeMount() {
        var options = _getCookie(this.cookieName);
        if (options && Object.keys(options).length > 0) {
            this.hasAcceptedCookies = true;
            this.cookieTecnic = options["tecnic"];
            this.cookieAnalisis = options["analisis"];
            this.cookieMarketing = options["marketing"];
        }
    }

    function openModal() {
        this.isModalOpen = true;
        this.cookieAnalisis = false;
        this.cookieMarketing = false;
        setTimeout(() => {
            _enableDocumentScroll(false);
        }, 200);
    }

    function closeModal() {
        this.isModalOpen = false;
        this.cookieAnalisis = true;
        this.cookieMarketing = true;

        setTimeout(() => {
            _enableDocumentScroll(true);
        }, 200);
    }

    function acceptCookies() {
        _setCookie(
            this.cookieName,
            {
                countryCode: this.countryCode,
                tecnic: this.cookieTecnic,
                analisis: this.cookieAnalisis,
                marketing: this.cookieMarketing,
            },
            365
        );
        gtag("consent", "update", {
            ad_storage: this.cookieMarketing ? "granted" : "denied",
            analytics_storage: this.cookieAnalisis ? "granted" : "denied",
        });
        fbq("consent", this.cookieMarketing ? "grant" : "revoke");
        this.isModalOpen = false;
        this.hasAcceptedCookies = true;
    }

    function acceptAllCookies() {
        _setCookie(
            this.cookieName,
            {
                countryCode: this.countryCode,
                tecnic: this.cookieTecnic,
                analisis: true,
                marketing: true,
            },
            365
        );
        this.isModalOpen = false;
        this.hasAcceptedCookies = true;
    }

    function rejectAllCookies() {
        _setCookie(
            this.cookieName,
            {
                countryCode: this.countryCode,
                tecnic: true, //mandatory
                analisis: false,
                marketing: false,
            },
            365
        );
        this.isModalOpen = false;
        this.hasAcceptedCookies = true;
    }

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();
        const el = this.$refs.cookieMonsterModalContent;
        if (el && !el.contains(event.target)) {
            _removeOnClickOutsideEvent(this);
            this.isModalOpen = false;
        }
    }

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _addOnClickOutsideEvent(this);
                _enableDocumentScroll(false);
            } else {
                _enableDocumentScroll(true);
            }
        });
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.cookieMonsterModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    function _removeOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.cookieMonsterModal.removeEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    /**
     * Check if the page must be in responsive mode.
     * NOTE: it allow us to reactively change the LAYOUT_MODE
     * @returns {boolean}
     */
    function isResponsiveMode() {
        return this.widthScreenPx <= 768;
    }

    function countriesWithCookieLaw() {
        return [
            "AT", // austria
            "BE", // belgium
            "BG", // bulgaria
            "HR", // croatia
            "CZ", // czechia
            "CY", // cyprus
            "DK", // denmark
            "EE", // estonia
            "FI", // finland
            "FR", // france
            "DE", // germany
            "HU", // hungary
            "IE", // ireland
            "IT", // italy
            "LV", // latvia
            "LT", // lithuania
            "LU", // luxemburg
            "MT", // malta
            "NL", // netherlands
            "NO", // norway
            "PL", // poland
            "PT", // portugal
            "SK", // slovakia
            "ES", // spain
            "SE", // serbia
            "GB", // united kingdom + north ireland
            "UK", // united kingdom
            "GR", // greece
            "RO", // romania
        ];
    }

    function hasLawWithCookies() {
        return this.countriesWithCookieLaw.indexOf(this.countryCode) > -1;
    }

    function hasToCheckGDPR() {
        var hasToCheck = !this.hasAcceptedCookies && this.hasLawWithCookies;
        if (!this.hasLawWithCookies) {
            _setCookie(
                this.cookieName,
                {
                    countryCode: this.countryCode,
                    tecnic: true,
                    analisis: true,
                    marketing: true,
                },
                365
            );
        }
        return hasToCheck;
    }

    // Cookie management
    function _setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie =
            name +
            "=" +
            (btoa(JSON.stringify(value)) || "") +
            expires +
            ";path=/;domain=.wikiloc.com";
    }

    function _getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                return JSON.parse(atob(c.substring(nameEQ.length, c.length)));
            }
        }
        return null;
    }

    function _eraseCookie(name) {
        document.cookie =
            name +
            "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.wikiloc.com";
    }
})();
